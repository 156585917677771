import React, {useEffect, useState} from 'react';
import WizardLayout from "../Layouts/WizardLayout";
import DatePicker from 'react-datepicker';
import {addDays} from 'date-fns';
import {Redirect} from 'react-router-dom';
import { withRouter, RouteComponentProps } from "react-router-dom";
import TagManager from '../gtm/gtm'

const Step3: React.FC<RouteComponentProps> = ({history}) => {
    const handleInputChange = e => {
        const {name, value} = e.target
        setValues({...values, [name]: value})
    }
    const [values, setValues] = useState({date: new Date(), term: ''})
    const [finished, setFinished] = useState(false);

    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    }, [])

    const submit = async e => {
        e.preventDefault();
        const result = await fetch(`/users/${localStorage.getItem('inshur-userId')}/accounts/${localStorage.getItem('inshur-accountId')}/policy`, {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('inshur-userToken')}`
            },
            body: JSON.stringify({ date: values.date.toISOString(), term: values.term})
        });

        if (result.status >= 200 && result.status < 300) {
            TagManager.dataLayer({event: 'policyTermSelected', policyTerm: values.term});
            setFinished(true);
        }

    }

    return (
        <WizardLayout>
            {finished && <Redirect to="/step4" />}
            <section className="form-section">
                <div className="container">
                    <form action="#" className="step-form">
                        <div className="step-4">
                            <div className="heading">
                                <strong className="subtitle">Step 4</strong>
                                <h1>Select a policy term</h1>
                                <p>Please choose the date when you would like your policy to start, and how often you
                                    wish to renew.</p>
                            </div>
                            <div className="input-holder date">
                                {/*<input name="date" type="text" className="datepicker" placeholder="Policy start date…" onChange={handleInputChange} value={values['date']}/>*/}
                                <DatePicker minDate={new Date()} maxDate={addDays(new Date(), 30)} selected={values.date} onChange={date => handleInputChange({ target: { name: 'date', value: date }})} />


                            </div>
                            <div className="label-holder">
                                <label htmlFor="annually" className="radio-label">
                                    <input type="radio" className="date-radio" name="term" id="annually" value="annually" onChange={handleInputChange} checked={values.term === 'annually'}/>
                                        <span className="fake-input"></span>
                                        <span className="fake-label">
										<span className="text">
											<strong className="title">Annually</strong>
										</span>
									</span>
                                </label>
                                <label htmlFor="quarterly" className="radio-label">
                                    <input type="radio" className="date-radio" name="term" value="quarterly" onChange={handleInputChange} id="quarterly" checked={values.term === 'quarterly'} />
                                        <span className="fake-input"></span>
                                        <span className="fake-label">
										<span className="text">
											<strong className="title">Quarterly</strong>
										</span>
									</span>
                                </label>
                                <label htmlFor="monthly" className="radio-label">
                                    <input type="radio" className="date-radio" name="term" value="monthly" onChange={handleInputChange} id="monthly" checked={values.term === 'monthly'} />
                                        <span className="fake-input"></span>
                                        <span className="fake-label">
										<span className="text">
											<strong className="title">Monthly</strong>
										</span>
									</span>
                                </label>
                                <label htmlFor="weekly" className="radio-label">
                                    <input type="radio" className="date-radio" name="term" value="weekly" onChange={handleInputChange} id="weekly" checked={values.term === 'weekly'} />
                                        <span className="fake-input"></span>
                                        <span className="fake-label">
										<span className="text">
											<strong className="title">Weekly</strong>
										</span>
									</span>
                                </label>
                            </div>
                            <span className="message choosen-date">

							</span>
                            <div className="btn-holder">
                                <button onClick={() => history.push('/Step2')} className="btn btn-back">Back</button>
                                <button onClick={submit} className="btn btn-next" disabled={!values['date'] || !values['term']}>Next</button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </WizardLayout>
    )
}

export default withRouter(Step3);
