import React from 'react';
import WizardLayout from "../Layouts/WizardLayout";
import {Redirect} from 'react-router-dom';
import TagManager from '../gtm/gtm'

declare global {
    interface Window {
        Argyle: {
            create: (opts: any) => {
                open: () => {},
                close: () => {}
            };
        };
    }
}

class Step1 extends React.Component<any, { hasCanceled: boolean, flowFinished: boolean, partner: string }> {

    private argyle;

    createArgyle() {
        this.argyle = window.Argyle.create({
            pluginKey: '05c44a48-7ec3-4250-8b79-367d59bdeee7', //'9795e7b9-4328-4cd8-ae2c-46b198c1daa6',
            apiHost: 'https://api.argyle.io/v1', //'https://api-sandbox.argyle.io/v1',
            //userToken: 'user_token',
            dataPartners: [this.state.partner],
            addIncomeSourceLinkVisible: false,
            companyName: 'INSHUR',
            onAccountConnected: async ({ accountId, userId }) => {
                await fetch(`/users/${userId}/accounts/${accountId}`,{
                    method: 'PUT',
                    body: JSON.stringify({ partner: this.state.partner }),
                    headers: {
                        'content-type': 'application/json',
                        'authorization': `Bearer ${localStorage.getItem('inshur-userToken')}`
                    }
                })
                localStorage.setItem('inshur-accountId', accountId);

                this.setState({flowFinished: true});
                TagManager.dataLayer({event: 'signinSuccesful'});

            },
            onUserCreated: async ({ userId, userToken }) => {
                localStorage.setItem('inshur-userToken', userToken)
                localStorage.setItem('inshur-userId', userId)
                await fetch(`/users`,{
                    method: 'POST',
                    body: JSON.stringify({token: userToken, userId}),
                    headers: {
                        'content-type': 'application/json'
                    }
                })



            },
            onClose: () => {
                this.setState({hasCanceled: !this.state.flowFinished});
            }
        });
    }

    loginToArgyle(e) {
        e.preventDefault();
        this.createArgyle();
        this.argyle.open();
        TagManager.dataLayer({event: 'triggeredArgyle'});
    }

    constructor(props) {
        super(props);
        this.scrollToTop();
        this.createArgyle.bind(this);
        this.state = {
            hasCanceled: false,
            flowFinished: false,
            partner: ''
        };
    }

    scrollToTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    }

    componentDidMount() {
        // this.argyle.open();
    }

    setPartner(partner : string) {
        this.setState({partner});
    }

    render() {
        return (
            <WizardLayout>
                {/*{this.state.hasCanceled && <Redirect to="/" />}*/}
                {this.state.flowFinished && <Redirect to="/step2" />}
                <section className="form-section">
                    <div className="container">
                        <form action="#" className="step-form">
                            <div className="step-1">
                                <div className="heading">
                                    <strong className="subtitle">Step 1</strong>
                                    <h1>Select your primary app platform</h1>
                                    <p>This is the app you use most for your rideshare or delivery work. Support for
                                        multiple app platforms coming soon.</p>
                                </div>
                                <div className="form-row">
                                    <div className="col-6">
                                        <label onClick={() => this.setPartner('uber')} htmlFor="uber" className="radio-label">
                                            <input type="radio" name="platform" id="uber"/>
                                                <span className="fake-input"></span>
                                                <span className="fake-label">
											<img className="brand-img" src={require("../images/platform-uber.png")} alt="uber"/>
											<span className="text">
												<strong className="title">Uber</strong>
												<span className="category">Rideshare</span>
											</span>
										</span>
                                        </label>
                                        <label onClick={() => this.setPartner('postmates')} htmlFor="postmates" className="radio-label">
                                            <input type="radio" name="platform" id="postmates"/>
                                                <span className="fake-input"></span>
                                                <span className="fake-label">
											<img className="brand-img" src={require("../images/platform-postmates.png")}
                                                 alt="postmates"/>
											<span className="text">
												<strong className="title">Postmates</strong>
												<span className="category">Delivery</span>
											</span>
										</span>
                                        </label>
                                        <label onClick={() => this.setPartner('doordash')} htmlFor="doordash" className="radio-label">
                                            <input type="radio" name="platform" id="doordash"/>
                                                <span className="fake-input"></span>
                                                <span className="fake-label">
											<img className="brand-img" src={require("../images/platform-doordash.png")}
                                                 alt="doordash"/>
											<span className="text">
												<strong className="title">Doordash</strong>
												<span className="category">Delivery</span>
											</span>
										</span>
                                        </label>
                                        <label onClick={() => this.setPartner('grubhub')} htmlFor="grubhub" className="radio-label">
                                            <input type="radio" name="platform" id="grubhub"/>
                                                <span className="fake-input"></span>
                                                <span className="fake-label">
											<img className="brand-img" src={require("../images/platform-grubhub.png")} alt="grubhub"/>
											<span className="text">
												<strong className="title">grubhub</strong>
												<span className="category">Delivery</span>
											</span>
										</span>
                                        </label>
                                        <label onClick={() => this.setPartner('deliv')} htmlFor="deliv" className="radio-label">
                                            <input type="radio" name="platform" id="deliv"/>
                                                <span className="fake-input"></span>
                                                <span className="fake-label">
											<img className="brand-img" src={require("../images/platform-deliv.png")} alt="deliv"/>
											<span className="text">
												<strong className="title">deliv</strong>
												<span className="category">Delivery</span>
											</span>
										</span>
                                        </label>
                                    </div>
                                    <div className="col-6">
                                        <label onClick={() => this.setPartner('lyft')} htmlFor="lyft" className="radio-label">
                                            <input type="radio" name="platform" id="lyft"/>
                                                <span className="fake-input"></span>
                                                <span className="fake-label">
											<img className="brand-img" src={require("../images/platform-lyft.png")} alt="lyft"/>
											<span className="text">
												<strong className="title">Lyft</strong>
												<span className="category">Rideshare</span>
											</span>
										</span>
                                        </label>
                                        <label onClick={() => this.setPartner('instacart')} htmlFor="instacart" className="radio-label">
                                            <input type="radio" name="platform" id="instacart"/>
                                                <span className="fake-input"></span>
                                                <span className="fake-label">
											<img className="brand-img" src={require("../images/platform-instacart.png")}
                                                 alt="instacart"/>
											<span className="text">
												<strong className="title">Instacart</strong>
												<span className="category">Delivery</span>
											</span>
										</span>
                                        </label>
                                        <label onClick={() => this.setPartner('amazon_flex')} htmlFor="amazon-flex" className="radio-label">
                                            <input type="radio" name="platform" id="amazon-flex"/>
                                                <span className="fake-input"></span>
                                                <span className="fake-label">
											<img className="brand-img" src={require("../images/platform-amazon_flex.png")}
                                                 alt="amazon-flex"/>
											<span className="text">
												<strong className="title">Amazon Flex</strong>
												<span className="category">Delivery</span>
											</span>
										</span>
                                        </label>
                                        <label onClick={() => this.setPartner('shipt')} htmlFor="shipt" className="radio-label">
                                            <input type="radio" name="platform" id="shipt"/>
                                                <span className="fake-input"></span>
                                                <span className="fake-label">
											<img className="brand-img" src={require("../images/platform-shipt.png")} alt="shipt"/>
											<span className="text">
												<strong className="title">Shipt</strong>
												<span className="category">Delivery</span>
											</span>
										</span>
                                        </label>
                                        <label onClick={() => this.setPartner('caviar')} htmlFor="caviar" className="radio-label">
                                            <input type="radio" name="platform" id="caviar"/>
                                                <span className="fake-input"></span>
                                                <span className="fake-label">
											<img className="brand-img" src={require("../images/platform-caviar.png")} alt="caviar"/>
											<span className="text">
												<strong className="title">Caviar</strong>
												<span className="category">Delivery</span>
											</span>
										</span>
                                        </label>
                                        <label onClick={() => this.setPartner('dolly')} htmlFor="dolly" className="radio-label">
                                            <input type="radio" name="platform" id="dolly"/>
                                                <span className="fake-input"></span>
                                                <span className="fake-label">
											<img className="brand-img" src={require("../images/platform-dolly.png")} alt="dolly"/>
											<span className="text">
												<strong className="title">Dolly</strong>
												<span className="category">Services</span>
											</span>
										</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="btn-holder">
                                    <button className="btn btn-back">Back</button>
                                    <button className="btn btn-next" onClick={e => this.loginToArgyle(e)} disabled={!this.state.partner}>Next</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </WizardLayout>
        )
    }

}


export default Step1;
