import React, {useEffect, useState} from 'react';
import WizardLayout from "../Layouts/WizardLayout";
import {Redirect} from 'react-router-dom';
import { withRouter, RouteComponentProps } from "react-router-dom";
import TagManager from '../gtm/gtm'

const Step4: React.FC<RouteComponentProps> = ({history}) => {
    const handleInputChange = e => {
        const {name, value} = e.target
        setValues({...values, [name]: value})
    }
    const [values, setValues] = useState({coverage: ''})
    const [finished, setFinished] = useState(false);

    const submit = async e => {
        e.preventDefault();
        const result = await fetch(`/users/${localStorage.getItem('inshur-userId')}/accounts/${localStorage.getItem('inshur-accountId')}/coverage`, {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('inshur-userToken')}`
            },
            body: JSON.stringify({ date: values.coverage, })
        });

        if (result.status >= 200 && result.status < 300) {
            TagManager.dataLayer({
                event: 'coverageSelected',
                coverageName: values.coverage
            });
            setFinished(true);
        }

    }

    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    }, [])

    return (
        <WizardLayout>
            {finished && <Redirect to="/step5" />}
            <section className="form-section">
                <div className="container">
                    <form action="#" className="step-form">
                        <div className="step-5">
                            <div className="heading">
                                <strong className="subtitle">Step 5</strong>
                                <h1>Select your coverage</h1>
                                <p>Below you will find the different levels of coverage available. Read each one
                                    carefully then select the option you would like.</p>
                            </div>
                            <label htmlFor="silver" className="radio-label">
                                <input type="radio" name="coverage" id="silver" value="silver" onChange={handleInputChange} />
                                    <span className="fake-input"></span>
                                    <span className="fake-label">
									<span className="color silver"></span>
									<span className="text">
										<strong className="title">Silver</strong>
										<span className="category">Physical damage only</span>
										<span className="description">Coverage for damage to your vehicle when performing gig platform work in periods 1, 2 and 3. No liability coverage.</span>
									</span>
								</span>
                            </label>
                            <label htmlFor="gold" className="radio-label">
                                <input type="radio" name="coverage" id="gold" value="gold" onChange={handleInputChange} />
                                    <span className="fake-input"></span>
                                    <span className="fake-label">
									<span className="color gold"></span>
									<span className="text">
										<strong className="title">Gold</strong>
										<span className="category">Full Coverage</span>
										<span className="description">Full coverage for your vehicle and liability when performing gig platform work in periods 1, 2 and 3.</span>
									</span>
								</span>
                            </label>
                            {/*<label htmlFor="platinum" className="radio-label">*/}
                            {/*    <input type="radio" name="coverage" id="platinum" value="platinum" onChange={handleInputChange} />*/}
                            {/*        <span className="fake-input"></span>*/}
                            {/*        <span className="fake-label">*/}
							{/*		<span className="color platinum"></span>*/}
							{/*		<span className="text">*/}
							{/*			<strong className="title">Platinum</strong>*/}
							{/*			<span className="category">Full coverage w/ $750 deductible</span>*/}
							{/*			<span className="description">Coverage for damage to your vehicle when performing gig platform work in periods 1, 2 and 3.</span>*/}
							{/*		</span>*/}
							{/*	</span>*/}
                            {/*</label>*/}
                            {/*<label htmlFor="diamond" className="radio-label">*/}
                            {/*    <input type="radio" name="coverage" id="diamond" value="diamond" onChange={handleInputChange} />*/}
                            {/*        <span className="fake-input"></span>*/}
                            {/*        <span className="fake-label">*/}
							{/*		<span className="color diamond"></span>*/}
							{/*		<span className="text">*/}
							{/*			<strong className="title">Diamond</strong>*/}
							{/*			<span className="category">Full coverage w/ $500 deductible</span>*/}
							{/*			<span className="description">Coverage for damage to your vehicle when performing gig platform work in periods 1, 2 and 3.</span>*/}
							{/*		</span>*/}
							{/*	</span>*/}
                            {/*</label>*/}
                            <div className="btn-holder">
                                <button onClick={() => history.push('/Step3')} className="btn btn-back">Back</button>
                                <button className="btn btn-next" onClick={submit} disabled={!values.coverage}>Next</button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </WizardLayout>
    )
}

export default withRouter(Step4);
