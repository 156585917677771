import React, {useState} from 'react';
import WizardLayout from "../Layouts/WizardLayout";
import {Redirect} from 'react-router-dom';
import { withRouter, RouteComponentProps } from "react-router-dom";
import TagManager from '../gtm/gtm'

const EnterVin: React.FC<RouteComponentProps> = ({history}) => {
    const handleInputChange = e => {
        const {name, value} = e.target
        console.log({name, value});
        setValues({...values, [name]: value})
    }
    const [values, setValues] = useState({vinNumber: ''})
    const [finished, setFinished] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const submit = async e => {
        setSubmitting(true);
        e.preventDefault();
        const result = await fetch(`/users/${localStorage.getItem('inshur-userId')}/accounts/${localStorage.getItem('inshur-accountId')}/vehicles/vins`, {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('inshur-userToken')}`
            },
            body: JSON.stringify({ vinNumber: values.vinNumber })
        });

        if (result.status >= 200 && result.status < 300) {
            TagManager.dataLayer({event: 'vehicleVinEntered'});
            setFinished(true);
        }
    }

    return (
        <WizardLayout>
            {finished && <Redirect to="/step3" />}
            <section className="form-section">
                <div className="container">
                        <div className="step-3 vin">
                            <div className="heading">
                                <strong className="subtitle">Step 3</strong>
                                <h1>Enter your VIN</h1>
                                <p>Enter your 17-character VIN number below.</p>
                            </div>
                            <div className="input-holder">
                                <input minLength={17} maxLength={17} type="text" name="vinNumber" value={values.vinNumber} onChange={handleInputChange} placeholder="VIN number…"/>
                            </div>
                            <div className="btn-holder">
                                <button onClick={() => history.push('/Step2')} className="btn btn-back">Back</button>
                                <button onClick={submit} className="btn btn-next" disabled={submitting || !values.vinNumber || values.vinNumber.length !== 17}>Next</button>
                            </div>
                        </div>
                </div>
            </section>
        </WizardLayout>
    );
}

export default withRouter(EnterVin);
