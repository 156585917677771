import React, {useEffect} from 'react';
import WizardLayout from "../Layouts/WizardLayout";
import notAvailablePng from '../images/graphic-not-available-yet.png';
import {Link} from 'react-router-dom';
import TagManager from '../gtm/gtm'

const Step5: React.FC = () => {
    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
        TagManager.dataLayer({ event: 'userCompleted' });
    }, [])

    return (
        <WizardLayout>
            <section className="form-section">
                <div className="container">
                    <div className="form-message">
                        <div className="img-holder">
                            <img src={notAvailablePng} alt="product not available" />
                        </div>
                        <div className="heading">
                            <h1>Sorry, Gig Pro is not yet available in your state</h1>
                            <p>Gig Pro is currently still in an early Beta phase and has not yet been released in your
                                state. However, we have added you to our mailing list and will notify you once it is
                                available.</p>
                            <p>Have a question? Send us an email at <a href="mailto:gigpro@inshur.com">gigpro@inshur.com</a></p>
                        </div>
                        <div className="btn-holder">
                            <Link to="/" className="btn">Return to homepage</Link>
                        </div>
                    </div>
                </div>
            </section>
        </WizardLayout>
    )
}

export default Step5;
