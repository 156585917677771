import React, {useState} from 'react';
import logo from './images/logo.png';
import graphicHero from './images/platforms-graphic-hero.png'
import photoPng from './images/photo.png';
import uberPng from './images/platform-uber.png';
import starRatingPng from './images/star-rating.png';
import logoShortPng from './images/logo-short.png';
import {Link} from 'react-router-dom';
import classNames from 'classnames';


function Homepage() {
  const [showWith, updateShowWith] = useState(false);

  return (
      <div className="homepage">
        <div id="wrapper">
          <header id="header">
            <div className="container">
              <strong className="logo">
                <Link to="/">
                  <img src={logo} alt="Inshur" />
                </Link>
              </strong>
              <span className="btn">in Beta</span>
            </div>
          </header>
          <main id="main">
            <section className="intro-section">
              <div className="container">
                <div className="flex-holder">
                  <div className="heading-holder">
                    <div className="heading">
                      <strong className="subtitle">Gig pro insurance</strong>
                      <h1>Driving delivery or rideshare? Don’t put your vehicle at risk</h1>
                      <p>Did you know, most personal insurance policies don’t cover your vehicle for commercial or gig economy work.
                        We will work with your platform of choice to give you the flexible coverage you need.</p>
                      <div className="btn-holder">
                        <Link to="/step1">
                          <a href="#" className="btn">Get a quote now</a>
                        </Link>
                        <a href="#description-section" className="btn btn-outline">Learn more</a>
                      </div>
                    </div>
                    <div className="img-holder">
                      <img src={graphicHero} alt="Welcome to Inshur" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="description-section" className="description-section">
              <div className="container">
                <div className="text-holder">
                  <h1>How it works</h1>
                  <p>Most personal insurance policies don’t include commercial coverage, such as food delivery and passenger transport, so you risk losing your vehicle if you have an accident. Check your current policy to see what you're covered for.</p>
                  <p>With Gig Pro, you will be covered while you work. Register now to help shape this cover and be the first to hear when it’s available in your area.</p>
                </div>
                <div className="switcher-holder">
                  <div className="switcher">
                    <a href="#" onClick={e => { e.preventDefault(); updateShowWith(false); }} className={classNames('btn switch-btn', {'without': !showWith})}>Without Gig Pro</a>
                    <a href="#" onClick={e => { e.preventDefault(); updateShowWith(true); }} className={classNames('btn switch-btn', {'without': showWith})}>With Gig Pro</a>
                  </div>
                  {!showWith && <ul className="period-list">
                    <li className="yes">
                      <div className="icon tick green"/>
                      <div className="text">
                        <strong className="title">Period 0</strong>
                        <span>Your regular personal insurance</span>
                      </div>
                    </li>
                    <li className="no">
                      <div className="icon red"/>
                      <div className="text">
                        <strong className="title">Period 1</strong>
                        <span>Once you open up your app</span>
                      </div>
                    </li>
                    <li className="no">
                      <div className="icon red"/>
                      <div className="text">
                        <strong className="title">Period 2</strong>
                        <span>When you’re en route to your pickup</span>
                      </div>
                    </li>
                    <li className="no">
                      <div className="icon red"/>
                      <div className="text">
                        <strong className="title">Period 3</strong>
                        <span>Once you’re on the job</span>
                      </div>
                    </li>
                  </ul>}
                  {showWith && <ul className="period-list">
                    <li className="yes">
                      <div className="icon tick green"/>
                      <div className="text">
                        <strong className="title">Period 0</strong>
                        <span>Your regular personal insurance</span>
                      </div>
                    </li>
                    <li className="yes">
                      <div className="icon tick blue-border"/>
                      <div className="text">
                        <strong className="title">Period 1</strong>
                        <span>Once you open up your app</span>
                      </div>
                    </li>
                    <li className="yes">
                      <div className="icon tick blue-border"/>
                      <div className="text">
                        <strong className="title">Period 2</strong>
                        <span>When you’re en route to your pickup</span>
                      </div>
                    </li>
                    <li className="yes">
                      <div className="icon tick blue-border"/>
                      <div className="text">
                        <strong className="title">Period 3</strong>
                        <span>Once you’re on the job</span>
                      </div>
                    </li>
                  </ul>}
                </div>
              </div>
            </section>
            <section className="testimonials">
              <div className="container">
                <blockquote>
                  <div className="photo-holder">
                    <div className="photo">
                      <img src={photoPng} alt="Stefan - Uber Driver"/>
                    </div>
                    <div className="brand-img">
                      <img src={uberPng} alt="uber"/>
                    </div>
                  </div>
                  <div className="text">
                    <p>Easy, prompt, reliable. INSHUR removed all the pain from the process of getting insured.
                    </p>
                    <cite>Stefan, Uber Driver</cite>
                  </div>
                </blockquote>
                <div className="rating-block">
                  <img src={starRatingPng} alt="trustpilot"/>
                  <a href="https://www.trustpilot.com/review/inshur.com" target="_blank" className="link">
                    <i className="icon-new-window"/>
                  </a>
                </div>
              </div>
            </section>
            <section className="article-section">
              <div className="container">
                <div className="logo-holder">
                  <img src={logoShortPng} alt="Inshur"/>
                </div>
                <div className="heading">
                  <h1>Say hello to the future of insurance</h1>
                  <div className="text">
                    <p>INSHUR has already revolutionized rideshare insurance in New York and the UK and now we’re bringing our data-led insurance to gig economy cover. Since 2016 we’ve made it easy for over 40,000 drivers to get insurance quickly and hassle-free using our 100% digital product.
                    </p>
                  </div>
                  <a href="https://www.inshur.com" target="_blank" className="btn btn-outline">
                    Learn more about INSHUR <i className="icon-new-window"/>
                  </a>
                </div>
              </div>
            </section>
            <section className="get-quote">
              <div className="container">
                <div className="heading">
                  <h1>Don’t put your vehicle at risk
                  </h1>
                  <div className="text">
                    <p>Register securely now to help us shape our Gig Pro insurance to your needs. Just let us know what vehicle you’re using and what platform you primarily work for, and we’ll do the rest.
                    </p>
                    <Link to="/step1">
                      <a href="#" className="btn btn-light">Register now</a>
                    </Link>

                  </div>
                </div>
              </div>
            </section>
          </main>
          <footer id="footer">
            <div className="container">
              <ul className="footer-list">
                <li>
                  <span className="copyright"><a href="https://www.inshur.com">INSHUR</a> &copy; 2020. All Rights Reserved.</span>
                </li>
                <li><a href="https://inshur.com/privacy-policy">Privacy Policy</a></li>
                <li><a href="https://inshur.com/about-us/#contact-us">Contact us</a></li>
              </ul>
            </div>
          </footer>
        </div>
      </div>

  );
}

export default Homepage;
