import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Homepage from './Homepage';
import * as serviceWorker from './serviceWorker';
import {MemoryRouter as Router, Route} from 'react-router-dom';
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import Step5 from './Steps/Step5';
import './scss/main.scss';
import EnterVin from "./Steps/EnterVin";
import 'regenerator-runtime/runtime';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-MXDTWPM'
}

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
      <Router>
          <Route path="/" exact component={Homepage} />
          <Route path="/step1" exact component={Step1} />
          <Route path="/step2" exact component={Step2} />
          <Route path="/step3" exact component={Step3} />
          <Route path="/step4" exact component={Step4} />
          <Route path="/step5" exact component={Step5} />
          <Route path="/enter-vin" exact component={EnterVin} />
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
