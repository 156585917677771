import React from 'react';
import logo from '../images/logo.png';

const WizardLayout : React.FC<{}> = ({children}) => {
    return (
        <div id="wrapper">
            <header id="header">
                <div className="container">
                    <strong className="logo">
                        <a href="/">
                            <img src={logo} alt="INSHUR" />
                        </a>
                    </strong>
                    <a href="/" className="btn">in Beta</a>
                </div>
            </header>
            <main id="main">
                <section className="form-section">
                    <div className="container">
                        {children}
                    </div>
                </section>
            </main>
            <footer id="footer">
                <div className="container">
                    <ul className="footer-list">
                        <li>
                            <span className="copyright"><a href="/">INSHUR</a> &copy; 2020. All Rights Reserved.</span>
                        </li>
                        <li><a href="https://inshur.com/privacy-policy">Privacy Policy</a></li>
                        <li><a href="https://inshur.com/about-us/#contact-us">Contact us</a></li>
                    </ul>
                </div>
            </footer>
        </div>
    )
}
export default WizardLayout;
