import React, {useEffect} from 'react';
import WizardLayout from "../Layouts/WizardLayout";
import {useState} from 'react'
import {Link} from 'react-router-dom';
import { RouteComponentProps } from "react-router-dom";
import loader from '../images/ajax-loader.gif';
import TagManager from '../gtm/gtm'

interface Vehicle {
    id: string,
    account: string,
    created_at: Date,
    updated_at: Date,
    make: string,
    model: string,
    year: number,
    identification: string,
    type: string,
    partner: string
}

const Step22: React.FC<RouteComponentProps> = ({history}) => {
    const handleInputChange = e => {
        const {name, value} = e.target
        console.log('change', {name, value});
        setValues({...values, [name]: value})
    }
    const [values, setValues] = useState({loading: true, vinNumber: '', vehicles: [] as Array<Vehicle>, partner: ''})

    const loadVehicle = async (tries: number) => {
        if (tries > 20) {
            history.push('/enter-vin');
        }

        const response = await fetch(`/users/${localStorage.getItem('inshur-userId')}/accounts/${localStorage.getItem('inshur-accountId')}/vehicles`, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('inshur-userToken')}`
            }
        });

        if (response.status === 401 || response.status === 403) {
            alert('unauthd');
        } else {
            if (response.status === 404) {
                setTimeout(() => loadVehicle(tries += 1), 1000)
            } else {
                const result = await response.json();
                if (!result.vehicles.length) {
                    history.push('/enter-vin');
                } else {
                    setValues({...values, vehicles: result.vehicles as Array<Vehicle>, partner: result.partner, loading: false});
                }
            }
        }
    }

    const submit = async e => {
        e.preventDefault();
        const result = await fetch(`/users/${localStorage.getItem('inshur-userId')}/accounts/${localStorage.getItem('inshur-accountId')}/vehicles/vins`, {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('inshur-userToken')}`
            },
            body: JSON.stringify({ vinNumber: values.vinNumber })
        });

        if (result.status >= 200 && result.status < 300) {
            TagManager.dataLayer({ event: 'vehicleSelected' });
            history.push('/step3');
        }
    }


    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
        loadVehicle(0);
    }, // eslint-disable-next-line
        []);

    return (
        <WizardLayout>
            {values.loading && <div className="loader">
              <div className="loader__img-wrapper"><img alt="Please wait while we retrieve your vehicles" className="loader__img" src={loader} /></div>
              <span className="loader__text">Please wait while we retrieve your vehicles</span>
            </div>}

            {!values.loading && <section className="form-section">
              <div className="container">
                <form action="#" className="step-form">
                  <div className="step-3">
                    <div className="heading">
                      <strong className="subtitle">Step 3</strong>
                      <h1>Confirm your details</h1>
                      <p>Please double check the details below to ensure we have detected your vehicle
                        correctly.</p>
                    </div>
                      {values.vehicles.map(v => {
                          return (
                              <label key={v.id} htmlFor={`vin-number-${v.id}`} className="radio-label lg-label">
                                  <input type="radio" value={v.id} checked={values.vinNumber === v.id} name="vinNumber" id={`vin-number-${v.id}`} onChange={handleInputChange} />
                                  <span className="fake-input"></span>
                                  <span className="fake-label">
                                            <span className="img-holder">
                                                <img className="brand-img" src={require(`../images/platform-${values.partner}.png`)} alt={values.partner} />
                                                <img className="tick-img" src={require('../images/small-tick.svg')} alt="vehicle loaded"
                                                     width="20" height="20" />
                                            </span>
                                            <span className="dashed-line"></span>
                                            <span className="text">
                                                <strong className="title">{`${v.year} ${v.make} ${v.model}`}</strong>
                                                <span className="category">{v.identification}</span>
                                            </span>
                                        </span>
                              </label>
                          )
                      })}
                    <span className="message">Not your vehicle? Enter your<Link to="/enter-vin"> VIN number</Link></span>
                    <div className="btn-holder">
                      <button onClick={() => history.push('/Step1')} className="btn btn-back">Back</button>
                      <button disabled={!values.vinNumber} onClick={submit} className="btn btn-next">Next</button>
                    </div>
                  </div>
                </form>
              </div>
            </section>}

        </WizardLayout>
    )
}

export default Step22;
